/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      prevArrow:
        '<button type="button" class="slick-prev"><span>Previous</span></button>',
      nextArrow:
        '<button type="button" class="slick-next"><span>Next</span></button>',
    });
  }

  function positionArrows() {
    const height = $('.carousel__img').height() / 2;
    $('.carousel .slick-arrow').css({ top: height });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();

    $(window).on('load resize', () => {
      setTimeout(() => {
        positionArrows();
      }, 100);
    });
  };

  return {
    init,
  };
})();

export default carousel;
