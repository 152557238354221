const homeSideImgCTA = (() => {
  function scrollTriggerSideImgCTA() {
    const scrollPos = window.innerHeight / 2 + window.scrollY;
    const $elem = '.side-img-cta';

    if (!$($elem).hasClass('js-active') && $($elem)[0].offsetTop <= scrollPos) {
      $($elem).addClass('js-active');
    }
  }

  const init = () => {
    if (!$('.side-img-cta').length) {
      return;
    }

    $(window).on('scroll', scrollTriggerSideImgCTA);

    scrollTriggerSideImgCTA();
  };

  return {
    init,
  };
})();

export default homeSideImgCTA;
