const homeHero = (() => {
  const init = () => {
    if (!$('.hero-home').length) {
      return;
    }

    $('.hero-home').addClass('js-active');
  };

  return {
    init,
  };
})();

export default homeHero;
