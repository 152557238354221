const header = (() => {
  const init = () => {
    const $body = document.querySelector('body');
    const $header = document.querySelector('header');
    const $mainNavButton = $header.querySelector('.main-menu__button');
    const $searchButton = $header.querySelector('.search__button');
    const $innerButtons = $header.querySelectorAll('.inner-nav__button');
    const $fabButton = $header.querySelector('.fab__button');

    // ## Toggle Dropdowns
    function toggleDropdown(elem) {
      if (elem.getAttribute('aria-expanded') === 'false') {
        elem.setAttribute('aria-expanded', 'true');
        elem.nextElementSibling.setAttribute('aria-hidden', 'false');
      } else {
        elem.setAttribute('aria-expanded', 'false');
        elem.nextElementSibling.setAttribute('aria-hidden', 'true');
      }
    }

    // ## Close nav if clicked "out of bounds"
    $body.addEventListener('click', () => {
      if ($body.classList.contains('js-open-main')) {
        $body.classList.remove('js-open-main');
        $body.classList.remove('js-fixed');
        toggleDropdown($mainNavButton);
      }
    });

    $header.querySelector('.header__inner').addEventListener('click', e => {
      e.stopPropagation();
    });

    // ## MAIN NAVIGATION

    // toggle
    $mainNavButton.addEventListener('click', e => {
      let delay = 0;

      if ($body.classList.contains('js-open-search')) {
        delay = 300;
        $searchButton.click();
      }

      setTimeout(() => {
        toggleDropdown(e.target);

        if ($body.classList.contains('js-open-main')) {
          $body.classList.remove('js-open-main');
          $body.classList.remove('js-fixed');
        } else {
          $body.classList.add('js-open-main');
          $body.classList.add('js-fixed');

          if (window.scrollY > 0) {
            window.scrollTo(0, 0);
          }
        }
      }, delay);
    });

    // tab loops
    $header
      .querySelector('.main-menu__ancillary li:last-child a')
      .addEventListener('keydown', e => {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $mainNavButton.focus();
        }
      });

    $mainNavButton.addEventListener('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey &&
        e.target.getAttribute('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        $header.querySelector('.main-menu__ancillary li:last-child a').focus();
      }
    });

    // ## SEARCH

    // toggle
    $searchButton.addEventListener('click', e => {
      let delay = 0;

      if ($body.classList.contains('js-open-main')) {
        delay = 300;
        $mainNavButton.click();
      }

      setTimeout(() => {
        toggleDropdown(e.target);

        if ($body.classList.contains('js-open-search')) {
          $body.classList.remove('js-open-search');
        } else {
          $body.classList.add('js-open-search');

          if (window.scrollY > 0) {
            window.scrollTo(0, 0);
          }
        }
      }, delay);
    });

    // tab loops
    document
      .querySelector('.search__button[type="submit"]')
      .addEventListener('keydown', e => {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $searchButton.focus();
        }
      });

    $searchButton.addEventListener('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey &&
        e.target.getAttribute('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        document.querySelector('.search__button[type="submit"]').focus();
      }
    });

    // ## INNER NAVIGATION

    // toggle
    Array.prototype.slice.call($innerButtons, 0).forEach(item => {
      item.addEventListener('click', e => {
        if (e.target.getAttribute('aria-expanded') === 'false') {
          Array.prototype.slice
            .call(
              $header.querySelectorAll(
                '.inner-nav__button[aria-expanded="true"]',
              ),
              0,
            )
            // eslint-disable-next-line max-nested-callbacks
            .forEach(i => {
              toggleDropdown(i);
              $(i.nextElementSibling).slideToggle();
            });
        }

        toggleDropdown(e.target);
        $(e.target.nextElementSibling).slideToggle();
      });
    });

    // ## FAB CTAS

    // toggle
    $fabButton.addEventListener('click', e => {
      toggleDropdown(e.target);
    });

    // tab loops
    document
      .querySelector('.fab__ctas li:last-child a')
      .addEventListener('keydown', e => {
        if (e.keyCode === 9 && !e.shiftKey && window.innerWidth < 700) {
          e.preventDefault();
          $fabButton.focus();
        }
      });

    $fabButton.addEventListener('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey &&
        e.target.getAttribute('aria-expanded') === 'true'
      ) {
        e.preventDefault();
        document.querySelector('.fab__ctas li:last-child a').focus();
      }
    });
  };

  return {
    init,
  };
})();

export default header;
