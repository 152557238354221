import '@babel/polyfill';
import 'slick-carousel';
import 'object-fit-images';

import header from './object/header';
import sectionNav from './object/section-nav';
import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import studentStories from './object/students-stories';
import wordWrap from './object/word-wrap';
import homeHero from './object/home-hero';
import homePathways from './object/home-pathways';
import homeStory from './object/home-story';
import bgImgHome from './object/bg-cta-home';
import sideImgCTAHome from './object/home-side-img-cta';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  header.init();
  sectionNav.init();
  accordion.init();
  inlineVideo.init();
  instagram.init();
  twitterLoader.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  studentStories.init();
  wordWrap.init();
  homeHero.init();
  homePathways.init();
  homeStory.init();
  bgImgHome.init();
  sideImgCTAHome.init();
  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
