const sectionNav = (() => {
  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    const $sectionNav = document.querySelector('.section-nav__button');

    // ## Toggle Dropdowns
    function toggleDropdown(elem) {
      if (elem.getAttribute('aria-expanded') === 'false') {
        elem.setAttribute('aria-expanded', 'true');
      } else {
        elem.setAttribute('aria-expanded', 'false');
      }
    }

    $sectionNav.addEventListener('click', e => {
      $(e.target.nextElementSibling).slideToggle();
      toggleDropdown(e.target);
    });

    window.addEventListener('resize', e => {
      if (e.target.innerWidth > 1024) {
        // $sectionNav.nextElementSibling.removeAttribute('style');
      }
    });
  };

  return {
    init,
  };
})();

export default sectionNav;
