/**
 * Scroll-top scrolls page to the top on click
 * @param {*} $
 * @returns {init} Determine and run if scroll-top present
 */

const studentsStories = (() => {
  function init() {
    const container = $('.students-success__stories-block');

    if (!container.length) return;

    const win = $(window);
    const doc = $(document);
    const activeSectionClass = 'stories-active';
    const activeBlockClass = 'block-stories-active';
    const dotActiveClass = 'active';
    const dots = container.find('.pagination li');

    function handleActiveState(item, index) {
      const checkBlockTopOffset =
        (index !== 0
          ? item.offset().top - item.innerHeight() / 2
          : item.offset().top) <= doc.scrollTop() &&
        item.offset().top + item.innerHeight() / 2 > doc.scrollTop();

      if (checkBlockTopOffset) {
        item.addClass(activeSectionClass);
      } else {
        item.removeClass(activeSectionClass);
      }
      function findOffsetDifference() {
        return doc.scrollTop() - item.offset().top;
      }
    }

    function handleActiveDotState(item, index) {
      const checkBlockTopOffset =
        (index !== 0
          ? item.offset().top - item.innerHeight() / 2
          : item.offset().top) <= doc.scrollTop() + 1;

      if (checkBlockTopOffset) {
        dots.eq(index).addClass(dotActiveClass);
      } else {
        dots.eq(index).removeClass(dotActiveClass);
      }
    }

    const items = container
      .find('.students-success__stories-box')
      .each((index, item) => {
        const currIyem = $(item);

        win.on('scroll resize orientationchange load', () => {
          handleActiveState(currIyem, index);
          handleActiveDotState(currIyem, index);
        });
      });

    dots.on('click', 'span', e => {
      const target = $(e.target);
      const sectionIndex = target.parent().index();
      const currSection = items.eq(sectionIndex);

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: currSection.offset().top
          },
          500,
        );
    });

    win.on('scroll resize orientationchange load', () => {
      const checkBlockTopOffset =
        container.offset().top <= doc.scrollTop() &&
        container.offset().top + container.innerHeight() - items.eq(2).innerHeight() > doc.scrollTop();

      if (checkBlockTopOffset) {
        container.addClass(activeBlockClass);
      } else {
        container.removeClass(activeBlockClass);
      }
    });
  }

  return {
    init,
  };
})();

export default studentsStories;
