const wordWrap = (() => {
  const init = () => {
    const cta = $(
      '.program-list__program > a, .story-display__title > a, .story-display__quote, .genimglist__title h3 > a, .events3up__item h3 > a, .stat3up__cta > a, .story-home__title > a, .story-home__quote, .gen2col h3 > a, .gen3col h3 > a',
    );
    cta.html((index, element) => {
      // Remove trailing whitespaces
      const textWOWhite = element.trim();
      // separate the text by spaces
      const text = textWOWhite.split(' ');
      // drop the last word and store it in a variable
      const last = text.pop();
      // join the text back and if it has more than 1 word add the span tag
      // to the last word
      return `${text.join(' ')} ${
        text.length >= 0 ? `<span class="last-word">${' '}${last}<span class="arrow"></span></span>` : last
      }`;
    });
  };

  return {
    init,
  };
})();

export default wordWrap;
