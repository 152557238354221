const homeStory = (() => {
  function scrollTriggerStory() {
    const scrollPos = window.innerHeight / 2 + window.scrollY;
    const $elem = '.story-home';

    if (!$($elem).hasClass('js-active') && $($elem)[0].offsetTop <= scrollPos) {
      $($elem).addClass('js-active');
    }
  }

  const init = () => {
    if (!$('.story-home').length) {
      return;
    }

    $(window).on('scroll', scrollTriggerStory);

    scrollTriggerStory();
  };

  return {
    init,
  };
})();

export default homeStory;
