const bgImgHome = (() => {
  function scrollTriggerBgImg() {
    const scrollPos = window.innerHeight / 2 + window.scrollY;
    const $elem = '.bg-cta-home';

    if (!$($elem).hasClass('js-active') && $($elem)[0].offsetTop <= scrollPos) {
      $($elem).addClass('js-active');
    }
  }

  const init = () => {
    if (!$('.bg-cta-home').length) {
      return;
    }

    $(window).on('scroll', scrollTriggerBgImg);

    scrollTriggerBgImg();
  };

  return {
    init,
  };
})();

export default bgImgHome;
