const homePathways = (() => {
  function scrollTrigger() {
    const scrollPos = window.innerHeight / 2 + window.scrollY;
    const $elem = '.pathways';

    if (!$($elem).hasClass('js-active') && $($elem)[0].offsetTop <= scrollPos) {
      $($elem).addClass('js-active');
    }
  }

  const init = () => {
    if (!$('.pathways').length) {
      return;
    }

    $(window).on('scroll', scrollTrigger);

    scrollTrigger();
  };

  return {
    init,
  };
})();

export default homePathways;
